// container imports
import CardNotes from "@/containers/card-notes";
import SetStatus from "@/containers/set-status";

// components imports
import Avatar from "@/components/avatar";
import Button from "@/components/button";
import Input from "@/components/input";
import Categorized from "@/components/categorized";
import Info from "@/components/info";
import RestoreBtn from "@/components/restore-btn";
import ExtLink from "@/components/ext-link";
import Tags from "@/components/tags";

import {mapGetters} from "vuex";
import axios from "@/axios";
import NeoAnalyse from "@/components/analyse";

export default {
    name: "neo-associated-people",
    components: {
        "neo-info": Info,
        "neo-tags": Tags,
        "neo-ext-link": ExtLink,
        "neo-button": Button,
        "neo-input": Input,
        "neo-restore-btn": RestoreBtn,
        "neo-avatar": Avatar,
        "neo-set-status": SetStatus,
        "neo-categorized": Categorized,
        "neo-card-notes": CardNotes,
        NeoAnalyse,
    },
    props: ["content", "setStatus", "restore", "tab", "component", "showNotes", "hideKeyFacts", "moreInfo"],
    data() {
        return {
            controls: {
                status: this.content.status,
            },
            tempHolder: "",
            editMode: false,
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getEntitiesRelationsAttributesData"]),
        entity_full_name() {
            let entity_component = this.getEntityComponents();
            // let entity_component = entity_components[2];
            let entity_mapper = this.getEntityFromKey(entity_component);
            if (entity_mapper.length > 0) {
                return entity_mapper[0].entity;
            } else {
                return "";
            }
        },
        entity_path() {
            let entity_components = [this.getEntityComponents()];
            let entity_names = [];
            entity_components.forEach((element) => {
                let x = this.getEntityFromKey(element);
                try {
                    entity_names.push(x[0].entity);
                } catch (error) {}
            });
            return entity_names.join(" > ");
        },
        risks() {
            return this.content.risk_categories.map((e) => ({tag: e}));
        },
        updateDoc() {
            return {
                case_id: this.$store.getters.getCaseId,
                entity: this.content.entity,
                entity_identifier: this.content.entity_identifier,
                entity_type: this.content.entity_type,
                type: this.content.type,
                id: this.content._id,
                url: this.content.url,
                attributes: this.content.attributes,
                notes: this.content.notes,
                is_collapse: this.content.is_collapse || false,
            };
        },
    },
    mounted() {},
    methods: {
        popIrrelevantData() {
            delete this.content.confidence_score;
            this.$emit("dataChanges", {...this.content, status: null, tab: this.tab});
        },
        getEntityIcon(identifier) {
            let icondata = this.$store.getters.getIconsData.filter((elem) => elem.key == identifier);
            if (icondata.length > 0) return icondata[0].icon_data;
            else return require("@/assets/icons/documents.svg");
        },
        getEntityComponents() {
            return this.content.title;
        },
        getEntityFromKey(entity_key) {
            return this.$store.getters.getEntityMapperData.filter((elem) => elem.entity_key == entity_key);
        },
        onStatChanges(status) {
            this.$emit("dataChanges", {...this.content, status});
        },
        startEdit() {
            const eid = this.content?.entity_type?._id;
            const item = this.getEntitiesRelationsAttributesData.entities.find((e) => e._id == eid);
            this.content.relations = this.content?.relations?.filter((relation) => {
                return relation.relationship_type !== "visual";
            });

            this.$store.dispatch("setSelectedEntityData", {
                datatype: "entity",
                all_data: this.content,
                entity: item,
                editEntity: true,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");
        },
        cancelSave() {
            this.editMode = false;
            this.content.entity = this.tempHolder;
        },
        completeSave() {
            axios.put(`/entity-identifier`, this.updateDoc).then((response) => {});
            this.editMode = false;
        },
        onRagChanges(ragstatus) {
            this.$emit("dataChanges", {...this.content, entity_status: ragstatus});
        },
        onTagChanges(categories) {
            let cats = categories.map((e) => e.tag);
            this.$emit("dataChanges", {...this.content, risk_categories: cats});
        },
    },
};
